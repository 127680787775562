import React from 'react'

import {
        getDefaultWallets,
        RainbowKitProvider,
        ConnectButton,
    } from '@rainbow-me/rainbowkit'
import {
    chain,
    configureChains,
    createClient,
    WagmiConfig,
    } from 'wagmi'

import { infuraProvider } from 'wagmi/providers/infura'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'

const { chains, provider } = configureChains(
    [chain.mainnet],
    [
      //infuraProvider({ infuraId: '999c9e5ccc744d318e112d250b3a4a4c' }),
      alchemyProvider({ alchemyId: "Hh8fdQZL2nYcS0fYVKslLvSoIZM7Ml9L" }),
      publicProvider()
    ]
  );
  
const { connectors } = getDefaultWallets({
    appName: 'corg corg',
    chains
})
  
const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider
})

import MintView from './MintView'

import corg_a from '../img/corg_a.png'
import corg_b from '../img/corg_b.png'
import corg_c from '../img/corg_c.png'
import corg_d from '../img/corg_d.png'

const WebApp = () => {
    return(
        <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider chains={chains}>
                <div className="m-12 text-center">
                    <h1 className="text-8xl my-3">corg corg</h1>
                    <h2 className="text-6xl my-3">1,200 corg corgs</h2>
                </div>

                <div className="text-xl my-4 w-6/12 m-auto text-center">
                    corg corg corg corg corg corg corg corg corg corg corg corg corg corg corg
                </div>

                <div className="text-xl my-4 w-6/12 m-auto text-center"> 
                    by the creators of <a className="text-purple-400" target="_new" href="https://www.dastardlyducks.com/">Dastardly Ducks</a> for international corgi day. no roadmap, just corg.
                </div>

                <div className="text-xl my-4 w-4/12 m-auto text-center">
                    <img src={corg_a} style={{width:"50%", display:"inline-block"}} />
                    <img src={corg_d} style={{width:"50%", display:"inline-block"}} />
                    <img src={corg_b} style={{width:"50%", display:"inline-block"}} />
                    <img src={corg_c} style={{width:"50%", display:"inline-block"}} />
                </div>

                <div className="flex justify-center mt-8 mb-16">
                    <ConnectButton chainStatus="icon" accountStatus="address" />
                </div>

                <div className="text-xl my-4 w-6/12 m-auto text-center">
                    <h3 className="text-4xl my-3">giv me corg corg!!!</h3>

                    <MintView />

                    <h4 className="text-3xl my-6">secondary corg corg</h4>

                    <a href="https://opensea.io/collection/corg-corg" className="bg-cyan-400 hover:bg-cyan-700 text-white text-2xl font-bold py-4 px-6 m-4 rounded-md">OpenSea</a>
                    <a href="https://looksrare.org/collections/0x9a510542e56d4d1b571dbf2110fe9307a3fc0569" className="bg-cyan-400 hover:bg-cyan-700 text-white text-2xl font-bold py-4 px-6 m-4 rounded-md">LooksRare</a>
                </div>

                <div className="text-xl mb-24 w-6/12 m-auto text-center">
                    <h3 className="text-4xl mt-24 mb-6">moar corg corg??</h3>

                    <a target="_blank" rel="noopener" href="https://etherscan.io/address/0x9a510542e56d4d1b571dbf2110fe9307a3fc0569" className="bg-cyan-400 hover:bg-cyan-700 text-white text-2xl font-bold py-4 px-6 m-8 rounded-md">SMRT corgtract</a>
                    <br /><br /><br />
                    <a target="_blank" rel="noopener" href="https://twitter.com/corgcorgnft" className="bg-cyan-400 hover:bg-cyan-700 text-white text-2xl font-bold py-4 px-6 m-8 rounded-md">birb site</a>
                </div>
            </RainbowKitProvider>
        </WagmiConfig>
    )
}

export default WebApp