import React, { useState, useEffect } from 'react'
import { useContractRead, useContractWrite, useAccount, useContract } from 'wagmi'
import { parseEther } from 'ethers/lib/utils'
import ABI from '../abi'
import Swal from 'sweetalert2'
import { contractAddress } from '../const'

let lastHash, lastHash5, lastHash10: any = undefined

const CorgCorgCounter = () => {
    const { data, isError, isLoading } = useContractRead(
        {
            addressOrName: contractAddress,
            contractInterface: ABI,
        },
        'totalSupply',
        {
            onSuccess(data) {
                console.log('Success', data)
            },
            onError(error) {
                console.log('Error', error)
            }
        }
    )

    /*if(data) {
        const minted = data?.toString() ?? "0"
        return <h4 className="text-2xl">{minted} of 1200 adopted</h4> 
    } else {*/
        return <h4 className="text-2xl">1200 to adopt</h4> 
    //}
}

const MintView = () => {
    const { data: accountData } = useAccount()

    const { data: mintData, write: writeMint} = useContractWrite(
        {
            addressOrName: contractAddress,
            contractInterface: ABI,
        },
        'oneWoof',
        {
            args: [{value: parseEther("0.005")}]
        }
    )

    const { data: mint5Data, write: writeMint5} = useContractWrite(
        {
            addressOrName: contractAddress,
            contractInterface: ABI,
        },
        'fiveArfs',
        {
            args: [{value: parseEther("0.02")}]
        }
    )

    const { data: mint10Data, write: writeMint10} = useContractWrite(
        {
            addressOrName: contractAddress,
            contractInterface: ABI,
        },
        'tenBarks',
        {
            args: [{value: parseEther("0.035")}]
        }
    )

    if (mintData && mintData.hash !== lastHash) {
        lastHash = mintData.hash
        Swal.fire("Success", `1 corg corg on the way`, 'success')
    }

    if (mint5Data && mint5Data.hash !== lastHash5) {
        lastHash5 = mint5Data.hash
        Swal.fire("Success", `5 corg corg on the way`, 'success')
    }

    if (mint10Data && mint10Data.hash !== lastHash10) {
        lastHash10 = mint10Data.hash
        Swal.fire("Success", `10 corg corg on the way`, 'success')
    }

    if(!accountData) {
        return(
            <div className="my-2">
                <strong>corgnect to a wallet, plz</strong>
            </div>
        )
    }

    return(
        <div className="max-w-lg m-auto">
            <CorgCorgCounter />

            <div className="my-4 text-center">
                <button className="bg-cyan-400 hover:bg-cyan-700 text-white text-2xl lg:text-xl font-bold py-4 px-6 m-2 rounded-lg" onClick={() => writeMint()}>1 corg @ 0.005</button><br />
                <button className="bg-cyan-400 hover:bg-cyan-700 text-white text-2xl lg:text-xl font-bold py-4 px-6 m-2 rounded-lg" onClick={() => writeMint5()}>5 corg @ 0.02</button><br />
                <button className="bg-cyan-400 hover:bg-cyan-700 text-white text-2xl lg:text-xl font-bold py-4 px-6 m-2 rounded-lg" onClick={() => writeMint10()}>10 corg @ 0.035</button>
            </div>
        </div>
    )
}

export default MintView